import classNames from 'classnames';

import { CancelIcon, InformationIcon } from '../svgs';

import { type AlertVariant } from './alertVariant';
import styles from './alert.module.scss';

export type AlertProps = {
  className?: string;
  closeButtonLabel?: string;
  id?: string;
  message: string;
  onClose?: () => void;
  variant?: AlertVariant;
};

export const Alert = ({
  className,
  closeButtonLabel,
  id = '',
  message,
  onClose,
  variant,
}: AlertProps) => {
  return (
    <div
      className={classNames(styles.alert, className, {
        [styles[`alert__variant`]]: variant,
        [styles[`alert__variant--${variant}`]]: variant,
      })}
    >
      <div>
        <InformationIcon className={styles['alert__icon']} />
      </div>
      <p aria-live="polite" className={styles['alert__message']} id={id} role="alert">
        {message}
      </p>
      {onClose && (
        <div className={styles['alert__close-button-wrapper']}>
          <button
            aria-label={closeButtonLabel || 'close'}
            className={styles['alert__close-button']}
            onClick={onClose}
          >
            <CancelIcon className={styles['alert__button-icon']} />
          </button>
        </div>
      )}
    </div>
  );
};
