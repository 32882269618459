import classNames from 'classnames';
import { type ReactElement } from 'react';

import { RenderHTML } from '../render-html';

import { Card } from './card';
import styles from './text-image-card.module.scss';

export type TextImageCardProps = {
  ImageComponent: ReactElement;
  actionComponent?: ReactElement;
  description: string;
  headline: string;
  subheadline?: string;
};

export const TextImageCard = ({
  ImageComponent,
  actionComponent,
  description,
  headline,
  subheadline,
}: TextImageCardProps) => {
  const { className, ...rest } = ImageComponent.props;

  return (
    <Card className={styles.card} elevation={0} hasNoRadius>
      <div>
        {
          /* eslint-disable-next-line react/jsx-pascal-case */
          <ImageComponent.type
            {...rest}
            className={classNames([styles['card__image'], className])}
          />
        }
      </div>
      <div className={styles['card__content']}>
        <div className={styles['card__content-wrapper']}>
          <div>
            <h3 className={styles.card__headline}>{headline}</h3>
            {subheadline && <h4 className={styles.card__subheadline}>{subheadline}</h4>}
          </div>
          <RenderHTML
            className={classNames(styles.card__description, {
              [styles['card__description--title-2']]: !subheadline,
              [styles['card__description--title-3']]: subheadline,
            })}
            html={description}
          />
        </div>
        {actionComponent && <div>{actionComponent}</div>}
      </div>
    </Card>
  );
};
