import { getLocale } from 'next-intl/server';

import { DEFAULT_LOCALE } from '../../../../i18n/locales';

import { convertUrlSegmentToLanguageName } from './convert-url-segment-to-language-name';

export const getContentInCurrentLanguage = async <
  T1 extends { Name?: string | null | undefined },
  T2 extends { Language?: T1 | null | undefined }
>(
  items: Array<T2 | null> | null | undefined
) => {
  if (items === undefined || items === null || items.length === 0) {
    return undefined;
  }

  const localeURLSegment = await getLocale();
  const languageName = convertUrlSegmentToLanguageName(localeURLSegment);

  const isCurrentLanguageSupported =
    !process.env.TRANSLATABLE_LANGUAGE_CODES ||
    process.env.TRANSLATABLE_LANGUAGE_CODES.includes(languageName);

  const itemsInCurrentLanguage = isCurrentLanguageSupported
    ? items.filter((item) => item?.Language?.Name === languageName)
    : [];
  const itemsInDefaultLanguage = items.filter((item) => item?.Language?.Name === DEFAULT_LOCALE);

  if (itemsInCurrentLanguage.length > 0) {
    return itemsInCurrentLanguage;
  } else {
    return itemsInDefaultLanguage;
  }
};
