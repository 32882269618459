import { QueryClient } from '@tanstack/react-query';

import { useWithErrorHandlingCmsContentQuery } from '../_generated/gql-generated';
import { getContentInCurrentLanguage } from '../utils/get-content-in-current-language';

export const getWithErrorHandlingContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useWithErrorHandlingCmsContentQuery.fetcher(),
    queryKey: useWithErrorHandlingCmsContentQuery.getKey(),
  });

  const cmsContent = (await getContentInCurrentLanguage(data.SiteSettingsPage?.items))?.[0];

  return {
    somethingWentWrong:
      cmsContent?.SomethingWentWrong || 'Something went wrong, please try again later',
  };
};

export type ShareDropdownCmsContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getWithErrorHandlingContent>>>
>;
