export const getStringWithQueryParameters = (
  url: string,
  parameters: Record<string, number | string | null | undefined>
) => {
  try {
    const urlObject = new URL(url);

    for (const key of Object.keys(parameters)) {
      const value = parameters[key];
      if (value !== null && value !== undefined) {
        urlObject.searchParams.append(key, value.toString());
      }
    }

    return urlObject.toString();
  } catch {
    return url;
  }
};
