export const CURRENCY = 'USD';

export const NUMERIC_FORMAT_PROPS = {
  allowLeadingZeros: false,
  allowNegative: false,
  thousandSeparator: ',',
};
export const MONETARY_FORMAT_PROPS = {
  ...NUMERIC_FORMAT_PROPS,
  decimalScale: 0,
  prefix: 'USD ',
};
