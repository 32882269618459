import { GTM_EVENT_NAME_HEADER_CLICK, sendGtmEvent } from '@shared/utils';

export const handleGTMHeaderClickEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  const target = event.target as HTMLElement;
  const element = target.closest('a') || target.closest('button');
  const isMiddleClick = event.button === 1;

  if (
    !element ||
    (element.tagName !== 'A' && element.tagName !== 'BUTTON') ||
    (element.type === 'auxclick' && !isMiddleClick) ||
    element.getAttribute('data-ga-exclude-tracking')
  )
    return;

  // setTimeout is needed to delay the event push, so GTM auto variables are passed correctly to the event
  setTimeout(
    () =>
      sendGtmEvent({
        eventName: GTM_EVENT_NAME_HEADER_CLICK,
        label: element.getAttribute('data-submenu-item') ? 'sub-header' : undefined,
      }),
    0
  );
};
