import {
  type LearnContentCarouselCardFragment,
  type ProfileListBlock,
} from '../data/graphql/_generated/gql-generated';

const FEATURED_PROFILES_TYPE = 'ProfileListBlock';

export const getFeaturedPeopleOrAuthors = (card: LearnContentCarouselCardFragment | null) => {
  const featuredProfiles = card?.MainContent?.find(
    (content) => content?.ContentLink?.Expanded?.__typename === FEATURED_PROFILES_TYPE
  )?.ContentLink?.Expanded as ProfileListBlock;

  if (featuredProfiles) {
    return (
      featuredProfiles.ProfileList?.map(
        (profile) => profile?.ContentLink?.Expanded?.Name ?? ''
      ).filter(Boolean) ?? ['']
    );
  }

  return (
    card?.Authors?.map((author) => author?.ContentLink?.Expanded?.Name ?? '').filter(Boolean) ?? [
      '',
    ]
  );
};
