import {
  DefaultGuideImage,
  DefaultImage,
  DefaultPodcastImage,
  DefaultVideoImage,
} from '@shared/ui-components';

import { ContentType } from '../data/constants';

export const getContentFallbackImageSource = (contentType: number | null | undefined) => {
  switch (contentType) {
    case ContentType.Audio:
    case ContentType.Podcast:
      return DefaultPodcastImage;
    case ContentType.Video:
      return DefaultVideoImage;
    case ContentType.Guide:
      return DefaultGuideImage;
    case ContentType.Article:
    default:
      return DefaultImage;
  }
};
