import { type MembershipLevel } from '../enums';

export type MembershipApplicationDto = {
  eligibleForLevels: Array<keyof typeof MembershipLevel>;
  isUpgrading: boolean | null;
  membershipLevelStepData: MembershipLevelData;
  membershipTypeStepData: MembershipTypeStepData;
  membershipYear: number;
  orders: Order[] | undefined;
  personId: number;
  previousMembershipLevel: MembershipLevel | null;
  status: MembershipApplicationStatus | undefined;
  wizardSteps: WizardStepDTO[];
};

export type MembershipTypeStepData = {
  membershipType: MembershipType | null | undefined;
};

export type MembershipLevelData = {
  membershipLevel: MembershipLevel | null;
};

export type WizardStepDTO = {
  status: WizardStepStatus;
  step: WizardStepName;
};

export type Order = {
  isShipped: boolean;
  level: MembershipLevel;
};

export enum WizardStepName {
  GETTING_STARTED = 'Getting Started',
  MEMBERSHIP_LEVEL = 'Membership Level',
  MEMBERSHIP_TYPE = 'Membership Type',
  PRODUCTION = 'Production',
  REVIEW = 'Review',
  WELCOME = 'Welcome',
}

export enum WizardStepStatus {
  ACCESSIBLE = 'Accessible',
  DISABLED = 'Disabled',
}

export enum MembershipType {
  LifeMember = 'Life Member',
  QualifyingLifeMember = 'Qualifying and Life Member',
  QualifyingMember = 'Qualifying Member',
}

export enum MembershipApplicationStatus {
  PaymentFailed = 'PaymentFailed',
  PaymentPending = 'PaymentPending',
  Submitted = 'Submitted',
}
