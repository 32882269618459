import { type SelectOption } from '@shared/ui-components';

export enum PhoneType {
  ASSISTANTS_PHONE = 'Assistants Phone',
  CELL_PHONE = 'Cell Phone',
  FAX = 'Fax',
  HOME_PHONE = 'Home Phone',
  PAGER_PHONE = 'Pager Phone',
  PHONE = 'Phone',
}

export const APP_PHONE_TYPES = [PhoneType.CELL_PHONE, PhoneType.PHONE, PhoneType.HOME_PHONE];

export const APP_PHONE_TYPE_OPTIONS: Array<SelectOption<string>> = APP_PHONE_TYPES.map(
  (phoneType) => {
    if (phoneType === PhoneType.PHONE) {
      return {
        label: 'Business Phone',
        value: phoneType,
      };
    }

    return {
      label: phoneType,
      value: phoneType,
    };
  }
);
