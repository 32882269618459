import styles from './checkbox.module.scss';

export const Checkbox = ({
  checked,
  onChange,
}: {
  checked: boolean | undefined;
  onChange: () => void;
}) => {
  return (
    <label className={styles['checkbox-label']}>
      <input checked={checked} onChange={onChange} type="checkbox" />
    </label>
  );
};
