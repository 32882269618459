/* eslint-disable typescript-sort-keys/string-enum */

export enum AccountSettingsFormSection {
  Profile = 'Profile',
  PersonalInformation = 'PersonalInformation',
  ContactInformation = 'ContactInformation',
  ProfessionalInformation = 'ProfessionalInformation',
  LoginPassword = 'LoginPassword',
  SitePreferences = 'SitePreferences',
}
