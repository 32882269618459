import { ArticleIcon, AudioIcon, VideoIcon } from '@shared/ui-components';

import { ContentType } from '../data/constants';

export const getContentTypeIconComponent = (
  contentType: ContentType | number | null | undefined
) => {
  switch (contentType) {
    case ContentType.Audio:
    case ContentType.Podcast:
      return AudioIcon;
    case ContentType.Video:
      return VideoIcon;
    case ContentType.Article:
    case ContentType.Guide:
    default:
      return ArticleIcon;
  }
};
