// eslint-disable-next-line import/extensions
import vars from '@shared/util-styling/export.module.scss';

import { Breakpoint } from '../enums';

export const breakpoints = {
  [Breakpoint.XLarge]: Number.parseInt(vars.breakpointXL, 10),
  [Breakpoint.Large]: Number.parseInt(vars.breakpointL, 10),
  [Breakpoint.Medium]: Number.parseInt(vars.breakpointM, 10),
  [Breakpoint.Small]: Number.parseInt(vars.breakpointS, 10),
};
