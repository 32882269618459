import { type MembershipLevel } from '../enums/membership';

export type PaymentProductName = MembershipLevel | 'BackDues' | 'LateFees';

export enum PaymentProductType {
  Level = 0,
  BackDues = 1,
  LateFees = 2,
}

export type PaymentProductRecord = {
  amount: number;
  productId: number;
  productName: PaymentProductName;
  type: PaymentProductType;
};

export type ReceiptDto = {
  acceptedTermsAndConditions: boolean;
  additionalFees: Nullable<number>;
  attestedToProduction: boolean;
  companyName: Nullable<string>;
  companyPrePayment: Nullable<number>;
  disabilityRequested: boolean;
  hasEthicsComplaint: boolean;
  isOrderShipped: boolean;
  mdrtId: number;
  memberFullName: Nullable<string>;
  membershipType: Nullable<string>;
  orderNumber: Nullable<number>;
  paymentDate: Nullable<string>;
  paymentMethod: Nullable<string>;
  phoneNumber: Nullable<string>;
  products: PaymentProductRecord[];
  selfPrePayment: Nullable<number>;
  shouldDisplayDisabilityRequested: boolean;
  totalAmountPaid: Nullable<number>;
};
