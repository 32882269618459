import classNames from 'classnames';
import { type ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './score-gauge.module.scss';

const ScoreGaugeLayout = ({
  ariaLabel,
  isActive,
  isSecondary,
  labelSlot,
  scoreGaugeSlot,
  scoreSlot,
}: {
  ariaLabel?: string;
  isActive?: boolean;
  isSecondary?: boolean;
  labelSlot: ReactElement | string;
  scoreGaugeSlot: ReactElement;
  scoreSlot?: ReactElement;
}) => {
  const PARENT_CLASS = 'score-gauge';

  return (
    <div
      aria-label={ariaLabel}
      className={classNames(styles[`${PARENT_CLASS}`], {
        [styles[`${PARENT_CLASS}--secondary`]]: isSecondary,
        [styles[`${PARENT_CLASS}--active`]]: isActive,
      })}
      data-testid="score-gauge-wrapper"
    >
      <div aria-hidden="true" className={styles[`${PARENT_CLASS}__indicator-container`]}>
        {scoreGaugeSlot}
        {scoreSlot !== undefined && scoreSlot}
      </div>
      <div className={styles[`${PARENT_CLASS}__label`]} data-testid="score-gauge-text">
        {labelSlot}
      </div>
    </div>
  );
};

type ScoreGaugeProps = {
  ariaLabel?: string;
  isActive?: boolean;
  isSecondary?: boolean;
  score?: number;
  scoreLabel?: string | null;
};

export const ScoreGauge = ({
  ariaLabel,
  isActive,
  isSecondary,
  score = 0,
  scoreLabel,
}: ScoreGaugeProps) => {
  if (score === undefined || scoreLabel === undefined) {
    return (
      <ScoreGaugeLayout
        isSecondary={isSecondary}
        labelSlot={<Skeleton width={80} />}
        scoreGaugeSlot={
          <Skeleton circle height={isSecondary ? 56 : 96} width={isSecondary ? 56 : 96} />
        }
      />
    );
  }

  const scorePercent = score * 10;
  const radius = 48;
  const strokeWidth = 6;
  const innerRadius = radius - strokeWidth;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * 0.75;
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(135, ${radius}, ${radius})`;
  const offset = arc - (scorePercent / 100) * arc;

  const FILL_PARENT_CLASSNAME = 'score-gauge__indicator-fill';
  let fillClassModifier;

  switch (true) {
    case isSecondary && !isActive:
      fillClassModifier = 'secondary';
      break;
    case score >= 1 && score <= 3:
      fillClassModifier = 'range-one';
      break;
    case score >= 4 && score <= 7:
      fillClassModifier = 'range-two';
      break;
    default:
      fillClassModifier = 'range-three';
  }

  const fillClasses = classNames(
    styles[FILL_PARENT_CLASSNAME],
    styles[`${FILL_PARENT_CLASSNAME}--${fillClassModifier}`]
  );
  return (
    <ScoreGaugeLayout
      ariaLabel={ariaLabel}
      isActive={isActive}
      isSecondary={isSecondary}
      labelSlot={scoreLabel ?? ''}
      scoreGaugeSlot={
        <svg viewBox="0 0 96 96">
          <defs />
          <circle
            className={styles['score-gauge__indicator-base']}
            cx={radius}
            cy={radius}
            data-testid="score-gauge-base"
            r={innerRadius}
            strokeDasharray={dashArray}
            strokeWidth={strokeWidth}
            transform={transform}
          />
          <circle
            className={fillClasses}
            cx={radius}
            cy={radius}
            data-testid="score-gauge-fill"
            r={innerRadius}
            strokeDasharray={dashArray}
            strokeDashoffset={offset}
            strokeWidth={strokeWidth}
            transform={transform}
          />
        </svg>
      }
      scoreSlot={
        <span className={styles['score-gauge__score']} data-testid="score-gauge-number">
          {score}
        </span>
      }
    />
  );
};
