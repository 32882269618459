export const EDGEAccessGroup = 'EDGE Group';
export const EveryoneAccessGroup = 'Everyone';
export const TOTAccessGroup = 'TOT';

export const EDGEGeoLocationUSA = 'United States';
export const EDGEGeoLocationCanada = 'Canada';

enum EdgeAccessGroupCountry {
  UnitedStates = 222,
  Canada = 36,
}

export const EdgeAccessGroupCountryIds = [
  EdgeAccessGroupCountry.Canada,
  EdgeAccessGroupCountry.UnitedStates,
];
