import { getLocale } from 'next-intl/server';

import { mapOptimizelyLocaleToIntlLocale } from '@shared/utils';

export const getContentFormattedDateString = async (createdDateTimeFullString: string) => {
  const createdDateTime = new Date(createdDateTimeFullString);
  const locale = await getLocale();

  return createdDateTime.toLocaleDateString(mapOptimizelyLocaleToIntlLocale(locale), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};
