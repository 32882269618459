import { getTextFromHtmlString } from './get-text-from-html-string';

const hasAnchorTags = (text: string | null | undefined) => {
  return ['<a', 'href', '</a>'].some((anchorString) => text?.includes(anchorString));
};

export const getCardLinkDescription = (
  text: string | null | undefined,
  fallback: string | null | undefined
) => {
  // Since Card component is an anchor tag itself we need to check if text has
  // any anchor tags inside. If that's the case we need to use a fallback,
  // otherwise, we get hydration errors
  if (text && !hasAnchorTags(text)) {
    return text;
  }

  // Fallback might contain anchor tags as well
  return (hasAnchorTags(fallback) ? getTextFromHtmlString(fallback) : fallback) ?? '';
};
