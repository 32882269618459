import { getFormatter, getLocale } from 'next-intl/server';

import { mapOptimizelyLocaleToIntlLocale } from '@shared/utils';

export const getFormattedStartEndDate = async (
  startDateTimeFullString: string,
  endDateTimeFullString: string
) => {
  const cmsLocale = await getLocale();
  const intlLocale = mapOptimizelyLocaleToIntlLocale(cmsLocale);
  const startDateTime = new Date(startDateTimeFullString);
  const endDateTime = new Date(endDateTimeFullString);
  const formatter = await getFormatter({ locale: intlLocale });

  const getFormattedFullDate = (date: number | Date) =>
    formatter.dateTime(date, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  const getFormattedMonthAndDay = (date: number | Date) =>
    formatter.dateTime(date, {
      day: 'numeric',
      month: 'long',
    });

  const getFormattedDay = (date: number | Date) =>
    formatter.dateTime(date, {
      day: 'numeric',
    });

  if (startDateTimeFullString === endDateTimeFullString) {
    return `${getFormattedFullDate(startDateTime)}`;
  }

  if (
    startDateTime.getMonth() === endDateTime.getMonth() &&
    startDateTime.getFullYear() === endDateTime.getFullYear()
  ) {
    return `${getFormattedMonthAndDay(startDateTime)} - ${getFormattedDay(
      endDateTime
    )}, ${startDateTime.getFullYear()}`;
  }

  if (
    startDateTime.getMonth() !== endDateTime.getMonth() &&
    startDateTime.getFullYear() === endDateTime.getFullYear()
  ) {
    return `${getFormattedMonthAndDay(startDateTime)} - ${getFormattedFullDate(endDateTime)}`;
  }

  return `${getFormattedFullDate(startDateTime)} - ${getFormattedFullDate(endDateTime)}`;
};
