import classnames from 'classnames';
import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ComponentType,
  type PropsWithChildren,
  type ReactElement,
} from 'react';

import { Card } from './card';
import styles from './card-link.module.scss';

export type CardLinkProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLAnchorElement> & {
    ImageComponent?: ReactElement;
    LinkComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
    actionClassName?: string;
    className?: string;
    to?: string;
  };

export const CardLink = ({
  ImageComponent,
  LinkComponent,
  actionClassName,
  children,
  className,
  onClick,
  to,
  ...props
}: PropsWithChildren<CardLinkProps>) => {
  const PARENT_CLASSNAME = 'card-link';

  const content = (
    <Card className={classnames(styles[PARENT_CLASSNAME], className)} isClickable>
      {ImageComponent && (
        // eslint-disable-next-line react/jsx-pascal-case
        <ImageComponent.type
          {...ImageComponent.props}
          className={classnames([
            styles[`${PARENT_CLASSNAME}__image`],
            ImageComponent.props?.className,
          ])}
          height={ImageComponent.props?.height ?? 280}
          width={ImageComponent.props?.width ?? 280}
        />
      )}
      <div className={styles[`${PARENT_CLASSNAME}__content`]}>{children}</div>
    </Card>
  );

  return to && LinkComponent !== undefined ? (
    <LinkComponent
      className={classnames(styles[`${PARENT_CLASSNAME}__action`], actionClassName)}
      href={to}
      {...props}
    >
      {content}
    </LinkComponent>
  ) : (
    <button
      className={classnames(styles[`${PARENT_CLASSNAME}__action`], actionClassName)}
      onClick={onClick}
      type="button"
      {...props}
    >
      {content}
    </button>
  );
};
