export const convertUrlSegmentToLanguageName = (urlSegment: string) => {
  const parts = urlSegment.split('-');
  const [language, region] = parts;

  if (parts.length !== 2) {
    return language;
  }

  return `${language}-${region.toUpperCase()}`;
};
