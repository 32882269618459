import { type ReactElement } from 'react';

import { ChipTheme, CountdownIcon } from '@shared/ui-components';

import { MembershipLevel } from '../../../enums';
import { MembershipType } from '../../../types';

import { COUNTDOWN_DAYS, COUNTDOWN_DAYS_HIGHLIGHTED } from './constants';

type ChipData = {
  icon?: ReactElement;
  label: string;
  onClick?: () => void;
  theme?: ChipTheme;
  to?: string;
};

export const getIsMemberAbleToUpgrade = ({
  currentMembershipLevel,
  isApplicationSubmitted,
  membershipType,
}: {
  currentMembershipLevel?: keyof typeof MembershipLevel | null;
  isApplicationSubmitted: boolean;
  membershipType: MembershipType | null | undefined;
}): boolean | undefined => {
  const isTOTMember = currentMembershipLevel === MembershipLevel.TOT;
  const isLifeMember = membershipType === MembershipType.LifeMember;

  return isApplicationSubmitted && !isLifeMember && !isTOTMember;
};

export const calculateIsLessThanThirtyDaysLeftUntilLateFees = (
  subtractCurrentDateFromLateFeeStartDate: number
) =>
  subtractCurrentDateFromLateFeeStartDate !== undefined &&
  subtractCurrentDateFromLateFeeStartDate <= COUNTDOWN_DAYS &&
  subtractCurrentDateFromLateFeeStartDate >= 0;

export const calculateIsThirtyToTenDaysLeftUntilLateFees = (
  subtractCurrentDateFromLateFeeStartDate: number
) =>
  subtractCurrentDateFromLateFeeStartDate !== undefined &&
  subtractCurrentDateFromLateFeeStartDate <= COUNTDOWN_DAYS &&
  subtractCurrentDateFromLateFeeStartDate > COUNTDOWN_DAYS_HIGHLIGHTED &&
  subtractCurrentDateFromLateFeeStartDate >= 0;

export const calculateIsLessThanTenDaysLeftUntilLateFees = (
  subtractCurrentDateFromLateFeeStartDate: number
) =>
  subtractCurrentDateFromLateFeeStartDate !== undefined &&
  subtractCurrentDateFromLateFeeStartDate <= COUNTDOWN_DAYS_HIGHLIGHTED &&
  subtractCurrentDateFromLateFeeStartDate >= 0;

export type InformationChipCMSContent = {
  applicationReceived: string;
  applyForMembership: string;
  finishApplication: string;
  membershipPageLink: string;
  numberOfDaysLeft: string;
  renewMembership: string;
  upgradeMembership: string;
};

// eslint-disable-next-line complexity
export const getInformationChipData = ({
  cmsContent,
  handleUpgradeMembershipClick,
  isApplicationInReview,
  isApplicationUnfinished,
  isCurrentMember,
  isLessThanTenDaysLeftUntilLateFees,
  isLessThanThirtyDaysLeftUntilLateFees,
  isMemberAbleToUpgrade,
  isNewUser,
  isPreviousButNotCurrentMember,
  isThirtyToTenDaysLeftUntilLateFees,
  isUpgrading,
  membershipApplicationUrl,
}: {
  cmsContent: InformationChipCMSContent;
  handleUpgradeMembershipClick?: () => void;
  isApplicationInReview: boolean | undefined;
  isApplicationUnfinished: boolean | undefined;
  isCurrentMember: boolean | undefined;
  isLessThanTenDaysLeftUntilLateFees: boolean | number | undefined;
  isLessThanThirtyDaysLeftUntilLateFees: boolean | number | undefined;
  isMemberAbleToUpgrade: boolean | undefined;
  isNewUser: boolean | undefined;
  isPreviousButNotCurrentMember: boolean | undefined;
  isThirtyToTenDaysLeftUntilLateFees: boolean | number | undefined;
  isUpgrading: boolean | undefined;
  membershipApplicationUrl: string;
}): ChipData | undefined => {
  if (isApplicationInReview) {
    return {
      label: cmsContent.applicationReceived,
      to: membershipApplicationUrl,
    };
  }

  if (
    (isApplicationUnfinished && !isLessThanThirtyDaysLeftUntilLateFees) ||
    (isApplicationUnfinished && isUpgrading)
  ) {
    return { label: cmsContent.finishApplication, to: membershipApplicationUrl };
  }

  if (isNewUser && !isLessThanThirtyDaysLeftUntilLateFees)
    return {
      label: cmsContent.applyForMembership,
      theme: ChipTheme.Emphasis,
      to: membershipApplicationUrl,
    };

  if (isPreviousButNotCurrentMember && !isLessThanThirtyDaysLeftUntilLateFees) {
    return {
      label: cmsContent.renewMembership,
      to: membershipApplicationUrl,
    };
  }

  if (isCurrentMember && isMemberAbleToUpgrade) {
    return { label: cmsContent.upgradeMembership, onClick: handleUpgradeMembershipClick };
  }

  if (isCurrentMember && !isMemberAbleToUpgrade) {
    return undefined;
  }

  if (isThirtyToTenDaysLeftUntilLateFees && !isApplicationInReview) {
    return {
      icon: <CountdownIcon />,
      label: cmsContent.numberOfDaysLeft,
      to: membershipApplicationUrl,
    };
  }

  if (isLessThanTenDaysLeftUntilLateFees && !isApplicationInReview) {
    return {
      icon: <CountdownIcon />,
      label: cmsContent.numberOfDaysLeft,
      theme: ChipTheme.Emphasis,
      to: membershipApplicationUrl,
    };
  }

  return undefined;
};
