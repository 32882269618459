import { type SocialPlatform } from '../enums';

import { type PhoneType } from './phone-type';

export type CustomerProfile = {
  address: Nullable<Address[]>;
  affiliatedCompanyId: Nullable<number>;
  agencyCompanyId: Nullable<number>;
  agentId: Nullable<string>;
  annualDonorLevel: Nullable<string>;
  annualTotalDonations: Nullable<number>;
  approvedSocialLogins: SocialPlatform[];
  bio: Nullable<string>;
  birthday: string;
  brokerDealerCompanyId: Nullable<number>;
  communicationTopics: CommunicationTopics;
  companyID: Nullable<number>;
  companyName: Nullable<string>;
  contactPermission: ContactPermission;
  cotProductionYear: Nullable<number>;
  country: Nullable<string>;
  countryCode: Nullable<string>;
  countryId: Nullable<number>;
  designations: number[];
  disability: Nullable<boolean>;
  donorLevel: Nullable<string>;
  emailAddress: Nullable<string>;
  emailOptIn: boolean;
  endOfGraceDate: Nullable<string>;
  firstName: Nullable<string>;
  firstYear: Nullable<number>;
  fullName: Nullable<string>;
  gapYears: Nullable<number>;
  gender: number;
  gsMembershipYears: number[];
  hasTotWaiver: boolean;
  isEthicsWatch: boolean;
  isValid: boolean;
  jobTitle: Nullable<string>;
  lastName: Nullable<string>;
  lastYear: Nullable<number>;
  lastYearOfMembership: Nullable<number>;
  lifetimeTotalDonations: Nullable<number>;
  mdrtId: Nullable<number>;
  memberDirectoryOptIn: boolean;
  memberType: Nullable<string>;
  memberTypeId: Nullable<number>;
  membershipYears: Nullable<number[]>;
  middleName: Nullable<string>;
  nickname: Nullable<string>;
  pastPresident: boolean;
  personId: Nullable<number>;
  personalCompany: Nullable<string>;
  phoneNumbers: Nullable<PhoneNumber[]>;
  prefix: Nullable<string>;
  primaryLanguage: Nullable<number>;
  profilePicture: ProfilePicture;
  promptUserToReviewData: boolean;
  secondEmail: Nullable<string>;
  secondEmailAddress: Nullable<string>;
  selectedPhoneType: Nullable<PhoneType>;
  smsOptIn: boolean;
  suffix: Nullable<string>;
  totProductionYear: Nullable<number>;
  totWaiverType: Nullable<TotWaiverType>;
  totalBackduesYear: Nullable<number>;
  totalCOT: Nullable<number>;
  totalL: Nullable<number>;
  totalMembership: Nullable<number>;
  totalProductionYear?: Nullable<number>;
  totalQ: Nullable<number>;
  totalQL: Nullable<number>;
  totalTOT: Nullable<number>;
  username: Nullable<string>;
  weChatId: Nullable<string>;
  yearOfFirstLicense: Nullable<string>;
};

export type CommunicationTopics = {
  businessAnnouncements: boolean;
  contentNewsletter: boolean;
  mdrtFoundation: boolean;
  mdrtMeetings: boolean;
  mdrtStore: boolean;
  memberBenefits: boolean;
};

export type CenterCommunicationTopics = {
  businessAnnouncements: boolean;
  contentNewsletter: boolean;
  mdrtMeetings: boolean;
  memberBenefits: boolean;
};

export type ContactPermission = {
  smsPermission: boolean;
};

export enum AddressType {
  BUSINESS_ADDRESS = 'Business Address',
  PO_BOX_ADDRESS = 'PO Box Address',
}

export type Address = {
  city: Nullable<string>;
  country: Nullable<string>;
  countryCode: Nullable<string>;
  countryId: Nullable<number>;
  county: Nullable<string>;
  formattedAddress: Nullable<string>;
  line1: Nullable<string>;
  line2: Nullable<string>;
  line3: Nullable<string>;
  line4: Nullable<string>;
  postalCode: Nullable<string>;
  stateOrProvinceId: Nullable<number>;
  stateProvince: Nullable<string>;
  type: Nullable<AddressType>;
};

export type ProfilePicture = {
  mediumSizePictureUri: Nullable<string>;
  originalSizePictureUri: Nullable<string>;
  smallSizePictureUri: Nullable<string>;
};

export type PhoneNumber = {
  areaCode: Nullable<string>;
  countryCode: Nullable<string>;
  phoneNumber: Nullable<string>;
  phoneType: Nullable<string>;
};

export enum TotWaiverType {
  TOT_10_YEARS = 'U',
  TOT_CHAIRMAN = 'Y',
}
