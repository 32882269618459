import { dateSubstractor } from '@shared/utils';

import {
  calculateIsLessThanTenDaysLeftUntilLateFees,
  calculateIsLessThanThirtyDaysLeftUntilLateFees,
  calculateIsThirtyToTenDaysLeftUntilLateFees,
  getIsMemberAbleToUpgrade,
} from '../data/get-chips-data/utils/helpers';
import { type ApplicationDefinition } from '../types/application-definition';
import { type CustomerProfile } from '../types/customer-profile';
import {
  MembershipApplicationStatus,
  type MembershipApplicationDto,
} from '../types/membership-application';

export const getMembershipInformation = ({
  applicationDefinition,
  customerProfile,
  membershipApplication,
}: {
  applicationDefinition?: ApplicationDefinition;
  customerProfile: CustomerProfile;
  membershipApplication: MembershipApplicationDto | null;
}) => {
  const currentYearDate = new Date();

  const isApplicationSubmitted =
    membershipApplication?.status !== undefined &&
    membershipApplication.status === MembershipApplicationStatus.Submitted;

  const isApplicationUnfinished =
    membershipApplication?.status !== undefined &&
    membershipApplication.status !== MembershipApplicationStatus.Submitted &&
    membershipApplication.status !== MembershipApplicationStatus.PaymentPending;

  const isNewUser = !membershipApplication?.status && customerProfile?.totalMembership === 0;

  const isPreviousButNotCurrentMember =
    applicationDefinition !== undefined &&
    customerProfile.totalMembership !== null &&
    customerProfile.totalMembership > 0 &&
    !customerProfile.membershipYears?.includes(applicationDefinition.membershipYear);

  const isCurrentMember =
    applicationDefinition !== undefined &&
    customerProfile?.membershipYears?.includes(applicationDefinition.membershipYear);

  const membershipType = membershipApplication?.membershipTypeStepData?.membershipType;

  const isMemberAbleToUpgrade = getIsMemberAbleToUpgrade({
    currentMembershipLevel: membershipApplication?.membershipLevelStepData?.membershipLevel,
    isApplicationSubmitted,
    membershipType,
  });
  const isUpgrading = Boolean(membershipApplication?.isUpgrading);

  const isApplicationInReview =
    (membershipApplication?.orders !== undefined &&
      isApplicationSubmitted &&
      membershipApplication?.orders?.length > 0 &&
      membershipApplication?.orders?.some((order) => !order.isShipped)) ||
    membershipApplication?.status === MembershipApplicationStatus.PaymentPending;

  const subtractCurrentDateFromLateFeeStartDate =
    applicationDefinition &&
    Math.round(
      dateSubstractor(applicationDefinition.lateFeeStartDate, currentYearDate.toDateString())
    );

  const isLessThanThirtyDaysLeftUntilLateFees =
    subtractCurrentDateFromLateFeeStartDate &&
    calculateIsLessThanThirtyDaysLeftUntilLateFees(subtractCurrentDateFromLateFeeStartDate);

  const isThirtyToTenDaysLeftUntilLateFees =
    subtractCurrentDateFromLateFeeStartDate &&
    calculateIsThirtyToTenDaysLeftUntilLateFees(subtractCurrentDateFromLateFeeStartDate);

  const isLessThanTenDaysLeftUntilLateFees =
    subtractCurrentDateFromLateFeeStartDate &&
    calculateIsLessThanTenDaysLeftUntilLateFees(subtractCurrentDateFromLateFeeStartDate);

  return {
    isApplicationInReview,
    isApplicationUnfinished,
    isCurrentMember,
    isLessThanTenDaysLeftUntilLateFees,
    isLessThanThirtyDaysLeftUntilLateFees,
    isMemberAbleToUpgrade,
    isNewUser,
    isPreviousButNotCurrentMember,
    isThirtyToTenDaysLeftUntilLateFees,
    isUpgrading,
    subtractCurrentDateFromLateFeeStartDate,
  };
};
