/* eslint-disable @typescript-eslint/prefer-literal-enum-member */

export enum CommunicationPreferenceTopics {
  BusinessAnnouncements = 'Business Announcements',
  ContentNewsletter = 'Content Newsletter',
  MdrtFoundation = 'MDRT Foundation',
  MdrtMeetings = 'MDRT Meetings',
  MdrtStore = 'MDRT Store',
  MemberBenefits = 'Member Benefits',
}

export enum CenterCommunicationPreferenceTopics {
  BusinessAnnouncements = CommunicationPreferenceTopics.BusinessAnnouncements,
  ContentNewsletter = CommunicationPreferenceTopics.ContentNewsletter,
  MdrtMeetings = CommunicationPreferenceTopics.MdrtMeetings,
  MemberBenefits = CommunicationPreferenceTopics.MemberBenefits,
}
